import { useTranslation } from 'react-i18next';

import CarouselBase from 'src/components/Carousels/CarouselBase';

import { carouselsData } from './const';

const CarouselBrands = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="carousel">
        <h3 className="carousel__title">
          {t('brands.titles.exploreBrandOffers')}
        </h3>
        <CarouselBase autoplaySpeed={3000} autoplay infinite dots>
          {carouselsData.map(carousel => (
            <a
              key={carousel.id}
              className="carousel__wrap"
              href={carousel.link || '#'}
              draggable={false}
              role="banner"
            >
              <div className="carousel__img">
                <img className="cover-img" src={carousel.url} alt="brands" />
              </div>
            </a>
          ))}
        </CarouselBase>
      </div>
    </div>
  );
};

export default CarouselBrands;
