export const carouselsData: { id: number; url: string; link: string }[] = [
  {
    id: 4,
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/banner/200524-1.jpeg',
    link: '',
  },
  {
    id: 5,
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/banner/200524-2.jpeg',
    link: '',
  },
  {
    id: 6,
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/banner/200524-3.jpeg',
    link: '',
  },
  {
    id: 7,
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/banner/200524-4.jpeg',
    link: '',
  },
  {
    id: 8,
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/banner/200524-5.jpeg',
    link: '',
  },
  {
    id: 9,
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/banner/200524-6.jpeg',
    link: '',
  },
];
